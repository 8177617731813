import React from "react";
import { colors, viewports } from "../../style-vars";

const AppetizeStyles = () => (
  <style jsx global>{`
    .nav.is-lp {
      min-width: 100vw;
    }

    .appetize h1.ant-typography {
      font-size: 32px !important;
    }

    .appetize {
    }

    .appetize .special-image {
      border-radius: 32px !important;
    }

    .appetize .hero__content {
      padding: 0;
    }

    .appetize video#videoBG {
      object-fit: cover;
      width: auto;
    }
    .appetize .hero-b:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(159, 153, 153, 0) 100%
      );
      z-index: 1;
    }
    .appetize video#videoBG img {
      border-radius: 32px !important;
    }

    .partners {
      //margin: 100px 16px;
    }
    h2.partners__title.ant-typography {
      text-align: center;
    }
    .partners__logos {
      display: flex;
      justify-content: space-around;
      margin-top: 45px;
    }
    .partners__logos--logo {
      width: 78px;
      height: auto;
    }

    .why-merge {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 80px 20px 90px;
      background: #090d12;
      border-radius: 32px;
    }
    h1.why-merge__title.ant-typography {
      color: ${colors.white} !important;
      z-index: 5;
    }
    .why-merge__subtext {
      color: ${colors.white};
      text-align: left;
      z-index: 5;
      padding: 40px 0;
    }
    button.why-merge__cta {
      padding: 8px 24px;
      border-radius: 100px;
      z-index: 5;
      margin: 0 !important;
    }
    .why-merge__background-1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 330px;
    }
    .why-merge__background-1 img {
      border-top-left-radius: 32px;
    }
    .why-merge__background-2 {
      position: absolute;
      bottom: 0;
      left: 120px;
      width: 500px;
    }
    .why-merge__background-3 {
      display: none;
    }

    .benefits {
      margin-top: 118px;
    }
    .benefits__title {
      text-align: center;
      padding: 0 20px;
    }
    .benefits__sub-title {
    }
    .benefits__items-wrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      margin-top: 60px;
    }
    .benefits__items-info {
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 48px;
    }
    .benefits__subtext {
      font-weight: normal;
      font-size: 16px;
      color: #445469;
      margin-top: 24px;
    }
    .benefits__item {
      margin: 16px 0;
      display: flex;
    }
    .benefits__item span {
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      letter-spacing: -0.03em;
      color: #090d12;
      margin-left: 16px;
    }
    .benefits__images {
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
    .benefits__images--img.top {
      width: 100%;
      max-width: 200px;
      height: auto;
    }
    .benefits__images--img.middle {
      width: 100%;
      max-width: 164px;
      height: auto;
      margin-top: -30px;
      //margin-left: 40px;
      align-self: flex-start;
    }
    .benefits__images--img.bottom {
      width: 100%;
      max-width: 164px;
      height: auto;
      margin-top: -60px;
    }

    .innovation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 220px;
    }
    .innovation__image {
      flex: 0 1 50%;
    }
    .innovation__image--tablet {
      z-index: 5;
    }
    .innovation__image--shape {
      width: 45%;
      max-width: 300px;
      margin-top: -80%;
      margin-left: 20%;
      z-index: 4;
    }
    .innovation__info {
      flex: 0 1 50%;
      margin-top: 50px;
    }
    .innovation__info--title .blue-dot:after {
      transform: translateX(-7.5px) translateY(-2px) scale(1.2);
    }
    .innovation__info--subtext {
      font-size: 16px;
      line-height: 160%;
      color: #445469;
      margin-top: 24px;
      max-width: 90%;
      text-align: justify;
    }

    .support {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 80px 16px 340px;
      background: #090d12;
      border-radius: 32px;
      //margin-top: 120px;
      //margin-bottom: 200px;
    }
    h1.support__title.ant-typography {
      color: ${colors.white} !important;
      max-width: 650px;
      text-align: left;
    }
    .support__subtext {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: left;
      color: #bfc6d0;
      margin-top: 48px;
      max-width: 930px;
      padding: 0;
    }
    .support__images {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: absolute;
      bottom: -7%;
      left: 0;
    }
    .support__images-img {
      max-width: 165px;
      width: 100%;
    }
    .support__images-img.left {
      margin-bottom: -380px;
      margin-left: 200px;
    }
    .support__images-img.middle {
      max-width: 225px;
      margin-top: -40px;
    }
    .support__images-img.right {
      margin-left: -45%;
    }

    .biz {
      position: relative;
      margin-top: 100px;
      text-align: center;
    }
    .biz__title {
      max-width: 500px;
      text-align: center;
      margin: 0 auto;
    }
    .biz__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 80px;
    }
    .biz__item {
      flex: 0 1 100%;
      display: flex;
      align-items: center;
      margin: 12px auto;
    }
    .biz__item.placeholder {
      display: none;
    }
    .biz__item--image {
      max-width: 96px;
      width: 100%;
      height: auto;
    }
    .biz__item--title {
      margin-left: 24px;
      font-weight: bold;
      font-size: 24px;
      color: #090d12;
      text-align: left;
    }
    .biz__item--title:hover {
      color: #090d12;
      text-decoration: underline;
    }
    .biz__background {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .quotes {
      padding: 80px 20px;
      background: #090d12;
      border-radius: 32px;
      position: relative;
    }
    .quotes__double-quotes {
      width: 117px;
      //width: 100%;
    }
    .quotes__items-wrapper {
      display: flex;
      position: relative;
    }
    .quotes__item {
      //margin-top: 40px;
      color: ${colors.white};
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 670px;
    }
    .quotes__item--subtext {
      font-size: 20px;
      line-height: 160%;
    }
    .quotes__item--image {
      width: 70px;
      border-radius: 100px;
      border: 3px solid ${colors.white};
      margin: 20px auto 8px auto;
    }
    .quotes__item--image img {
      border-radius: 100px;
    }
    .quotes__item--title {
      font-size: 15px;
      font-weight: bold;
    }
    .quotes__item--sub-title {
      font-size: 12px;
      margin-top: 4px;
    }
    .quotes__quotes-bg {
      display: none;
    }
    .quotes__transparent {
      margin-left: 30%;
    }
    .quotes__circle-bg-big {
      display: none;
    }
    .quotes__circle-bg-small {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .quotes__buttons {
      display: flex;
      position: relative;
      width: 100px;
      margin: 0 auto 0;
    }
    .quotes__slide-button {
      background: none;
      outline: none;
      border: none;
    }
    .quotes__slide-button:hover {
      cursor: pointer;
    }
    .quotes__slide-button .outer {
      width: 32px;
      height: 32px;
      border-radius: 30px;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .quotes__slide-button.active .outer {
      border-color: #fff;
    }
    .quotes__slide-button .inner {
      width: 8px;
      height: 8px;
      border-radius: 30px;
      color: #fff;
      background: #fff;
    }

    .appetize .hero-b {
      margin: 40px 16px !important;
      max-width: 1440px;
    }
    .appetize .partners {
      margin: 60px 16px;
      max-width: 1440px;
    }
    .appetize .why-merge {
      margin: 20px 16px 0;
      max-width: 1440px;
    }
    .appetize .benefits {
      margin: 100px 16px 0;
      max-width: 1440px;
    }
    .appetize .innovation {
      margin: 60px 16px 0;
      max-width: 1440px;
    }
    .appetize .support {
      margin: 80px 0 80px;
      max-width: 1440px;
      width: 100%;
    }
    .appetize .biz {
      margin: 150px 10px 40px;
      width: 100%;
    }
    .appetize .biz-wrapper {
      max-width: 1440px;
      padding: 0 16px;
      margin: 0 auto;
    }
    .appetize .quotes {
      margin: 80px 0;
      max-width: 1440px;
    }

    @media ${viewports.mdOrSmaller} {
      .appetize .hero-b {
        padding: 16px;
      }
      .partners__logos {
        justify-content: center;
        flex-wrap: wrap;
      }
      .partners__logos--logo {
        width: 20vw;
        height: auto;
      }
    }

    @media ${viewports.mdOrBigger} {
      .benefits__items-wrapper {
        flex-direction: row;
        margin-top: 120px;
      }
      .appetize .benefits {
        margin: 118px 64px 0;
      }
      .appetize .support {
        margin: 120px 16px 180px;
        padding: 125px 20px 150px;
      }
      .appetize .biz-wrapper {
        padding: 0 20px;
      }
      .appetize .quotes {
        margin: 100px 30px;
      }

      .why-merge {
        align-items: center;
      }

      .benefits__items-info {
        margin-top: 0;
      }
      .ant-typography.benefits__sub-title {
        padding-right: 0;
      }
      .benefits__images--img.top {
        max-width: 200px;
      }
      .benefits__images--img.middle {
        align-self: center;
        max-width: 140px;
        margin-top: -20px;
        margin-left: -100px;
      }
      .benefits__images--img.bottom {
        max-width: 140px;
        margin-top: -60px;
      }

      .innovation {
        flex-direction: row;
      }
      .innovation__image--shape {
        width: 50%;
      }
      .innovation__info {
        margin-top: 0;
      }

      .support__images {
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        bottom: -220px;
      }
      .support__images-img {
        max-width: 200px;
      }
      .support__images-img.left {
        margin-bottom: 0;
        margin-left: 0;
      }
      .support__images-img.middle {
        max-width: 286px;
        margin-top: 40px;
      }
      .support__images-img.right {
        margin-left: 0;
      }
      h1.support__title.ant-typography {
        text-align: center;
      }
      .support__subtext {
        text-align: center;
        padding: 0 20px;
      }

      .biz__items {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
      }
      .biz__item {
        flex: 0 1 50%;
        margin: 40px auto;
      }

      .quotes {
        padding: 40px 20px;
      }
      .quotes__quotes-bg {
        display: block;
        position: absolute;
        top: 0;
        right: 50px;
      }
      .quotes__transparent {
        margin-left: 0;
      }
      .quotes__item--subtext {
        padding: 0 40px;
      }
      .quotes__item {
        margin-top: 0;
      }
    }

    @media ${viewports.lgOrBigger} {
      .appetize .hero-b {
        margin: 40px 20px;
      }
      .appetize .partners {
        margin: 60px;
      }
      .appetize .why-merge {
        margin: 60px 60px 0;
      }
      .appetize .benefits {
        margin: 120px 60px 0;
      }
      .appetize .innovation {
        margin: 80px 60px 0;
      }
      .appetize .support {
        margin: 120px 60px 300px;
      }
      .appetize .biz {
        margin: 80px 10px 0;
      }
      .appetize .quotes {
        margin: 100px 30px;
      }

      .appetize h1.ant-typography {
        font-size: 42px !important;
      }

      .partners__logos {
        justify-content: space-between;
      }

      .why-merge {
        padding: 215px 20px 140px;
      }
      .why-merge__background-3 {
        display: block;
        position: absolute;
        top: 18px;
        right: 72px;
        width: 160px;
      }
      .why-merge__subtext {
        padding: 40px 200px;
        text-align: center;
      }

      .benefits__title {
        padding: 0;
      }
      .benefits__images--img.top {
        max-width: 262px;
      }
      .benefits__images--img.middle {
        max-width: 192px;
        margin-top: -35px;
        margin-left: -100px;
      }
      .benefits__images--img.bottom {
        max-width: 192px;
        margin-top: -80px;
      }
      .ant-typography.benefits__sub-title {
        padding-right: 145px;
      }

      .innovation__info {
        margin-top: 50px;
      }

      .support__images {
        bottom: -240px;
      }
      .support__images-img {
        max-width: 227px;
      }
      .support__images-img.middle {
        max-width: 317px;
        margin-top: 40px;
      }

      .biz__item {
        flex: 0 1 35%;
      }
      .biz__item.placeholder {
        display: flex;
      }

      .quotes {
        padding: 128px 80px;
      }
      .quotes__item--subtext {
        padding: 0;
      }
      .quotes__circle-bg-big {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .quotes__circle-bg-small {
        display: none;
      }
      .quotes__item {
        margin-top: 60px;
        min-height: 450px;
      }
    }

    @media ${viewports.xlOrBigger} {
      .appetize .benefits {
        margin: 140px 160px 0;
      }
      .appetize .biz {
        margin: 140px 60px 40px;
      }

      .why-merge__subtext {
        padding: 40px 380px;
      }
      .benefits__images--img.top {
        max-width: 328px;
      }
      .benefits__images--img.middle {
        max-width: 240px;
        margin-top: -50px;
        margin-left: -35%;
      }
      .benefits__images--img.bottom {
        max-width: 240px;
        margin-top: -120px;
      }

      .support__images {
        bottom: -270px;
      }
      .support__images-img {
        max-width: 260px;
      }
      .support__images-img.middle {
        max-width: 360px;
      }

      .biz__item {
        flex: 0 1 30%;
      }

      .innovation__info--subtext {
        max-width: 70%;
      }

      .quotes {
        padding: 128px 186px;
      }
    }
  `}</style>
);

export default AppetizeStyles;
