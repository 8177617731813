// eslint-disable-next-line import/prefer-default-export
export const dolceHero = {
    title: "Bring your labor management under one roof with SpotOn Teamwork",
    subtext:
      "Simplify your day-to-day operation by bringing all aspects of managing labor– scheduling, tip distribution, payroll, and employee engagement into one solution.",
    heroImg: "dolce_hero.png",
    imageClassName: "fixed-height fixed-width top-adjustement",
    PrimaryCta: {
      ctaTitle: "Learn more",
      ctaTarget: "/products/labor-management/demo",
    },
    fatTitle: "Teamwork",
  };
  
  export const dolceTrusted = [
    {
      title: "1 central hub",
      subtext: "For scheduling, time punches, real-time sales, and analytics",
    },
    {
      title: "20 hours saved",
      subtext:
        "Per week on scheduling and labor management tasks with software",
    },
    {
      title: "20+",
      subtext: "Payroll integrations",
    },
    {
      title: "1-1k locations",
      subtext:
        "No matter how many locations you operate, save time with one-click payroll",
    },
  ];
  
export const dolceLargeFeatures = {
  sectionTitle: "Streamline scheduling, tip distribution, payroll, and labor compliance from one dashboard",
  featureBlocks: [
    {
      blockTitle: "Save time & reduce labor costs with integrated scheduling",
      blockImg: "dolce-a.png",
      blockSubTitle:
        "Say goodbye to creating schedules in excel sheets and keeping track of schedule changes through texts. We give you time back in your week and make scheduling easy.",
      blockList: [
        "Drag and drop scheduling templates to create a schedule in minutes",
        "Make data-driven schedules based on POS sales data to ensure you’re controlling your labor costs ",
        "Review, approve, or deny all PTO, shift trades, and sick time from your staff in one place",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/labor-management/demo",
      },
    },
    {
      blockTitle: "Do payroll in minutes",
      blockSubTitle:
        "Payroll can be the biggest headache of the week. With SpotOn Teamwork, you can simplify the entire process by bringing payroll directly into your labor management system. \n(Coming soon!)",
      blockImg: "dolce-b.png",
      blockList: [
        "Automate your tip distribution with even the most complex of rules and an unlimited number of locations",
        "Build trust between you and your employees and remain compliant by ensuring that the information on payroll is accurate and up-to-date",
        "Review and easily update clock-ins and break times with a 2-way sync to your SpotOn POS",
        "Less back and forth managing your labor with one tech stack",
      ],
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/labor-management/demo",
      },
    },
    {
      blockTitle: "Empower staff to manage their work life",
      blockSubTitle:
        "Give staff the ability to view and make changes to their schedule, see tips made, and receive communications from one app. With our SpotOn Teamwork App, your staff can:",
      blockImg: "dolce-c.png",
      blockList: [
        "See all their schedules for the week (or duration that you choose to set)",
        "Quickly request to swap or pick up available shifts",
        "Easily request PTO and sick time, with the ability for you to approve or deny",
        "Gain visibility into tips made for the night as soon as tips are distributed",
        "Receive direct or group messages in the app, keeping communications in one place",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/labor-management/demo",
      },
    },
    {
      blockTitle: "Ensure compliance. Protect your business",
      blockSubTitle:
        "With real-time data from the SpotOn Restaurant platform, you can automatically avoid compliance issues, future wage claims, and overpaying, by enforcing clock-ins to schedules. No matter how complex your scheduling or local labor compliance laws are, you can protect yourself and save on labor costing by restricting early or unscheduled clock-ins.",
      blockImg: "dolce-d.png",
      blockList: [],
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/labor-management/demo",
      },
    },
  ],
};

export const dolceBusinessTypesCar = {
  title: "The most customizable labor solution for restaurants small to big",
  subtext:
    "Whether you’re operating 1 restaurant or 100, Teamwork is right for you.",
  slides: [
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
    {
        bg: "casual-dining.png",
        cardTitle: "Casual dining",
        cardContent: "Tailor-made restaurant solutions",
        icon: "casual-dining.png",
        linkTo: "/casual-dining-pos",
    },
  ],
};

// export const marketingVideo = {
//   title: "Don’t just send emails. Connect with your customers",
//   videoBtn: "Watch video",
//   posterImg: "marketing-poster.png",
//   videoUrlId: "iSpoRZmN9D4",
// };

export const dolceSolutionsSection = {
  title:
    "Part of an end-to-end solution to run your restaurant",
  subText: `Combine the Teamwork with SpotOn’s other powerful restaurant tools that are tailor-made to help you boost efficiency, grow sales, and create a better guest experience.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const dolceWhiteGlove = {
    mainTitle: "Personalized service & support",
    title: "We’re in your corner",
    content:
      "Running a successful restaurant means overcoming major challenges, including attracting and retaining employees, reducing labor costs, and balancing complex compliance requirements. Our team is here to help. In addition to configuring your Teamwork labor management software to simplify and streamline operations for your business, we’ll also build out your real-time point-of-sale integration so that you and your staff can spend more time delighting your guests and less time on tedious bookkeeping.",
    ctaInfoPrimary: {
      ctaTitle: "Learn more",
      ctaTarget: "/products/labor-management/demo",
    },
  };

export const dolceTestimonials = {
  title: "What other restaurateurs are saying",
  subTitle: "",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const dolceCTA = {
  title: "Book your consultation.",
  ctaImg: "dolce-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/labor-management/demo",
  },
};

export const dolceSolutionsSlides = [
    {
      title: "Website",
      link: "/products/e-commerce",
      img: "E-commerce & websites.png",
    },
    {
      title: "Loyalty",
      link: "/products/loyalty",
      img: "Loyalty.png",
    },
    {
        title: "Reporting",
        link: "/products/reporting",
        img: "Reporting.png",
    },
    {
        title: "Online Ordering",
        img: "Online ordering.png",
        link: "/products/online-order-and-delivery",
    },
    {
        title: "Restaurant POS",
        link: "/restaurant-pos",
        img: "Restaurant.png",
    },
    {
        title: "Reserve",
        link: "/products/reserve",
        img: "Reserve.png",
    }, 
];

export const quotes = {
  subtext: `“I love having the opportunity to shape forward-thinking solutions for our industry. Tip distribution takes up a significant portion of our end-of-the-night closeout, so when we found Teamwork helped us decrease our time from one hour down to ten minutes we knew it was a no-brainer for our restaurants and for SpotOn.”`,
  imageName: "dolce-bob-dittel.png",
  imageTitle: "Michael Mina",
  imageSubtitle: "MINA Group",
};
