import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  dolceHero,
  dolceTrusted,
  dolceWhiteGlove,
  dolceLargeFeatures,
  dolceBusinessTypesCar,
  // marketingVideo,
  dolceSolutionsSection,
  dolceSolutionsSlides,
  dolceTestimonials,
  dolceCTA,
  quotes,
  // marketingFaqs,
} from "../../../data/product-pages/labor-management-data";
import HeroLaborManagement from "../../../components/Hero/HeroLaborManagement";
import ogImage from "../../../images/global_assets/og-image.png";
import Image from "../../../components/image";
import quotesBackground from "../../../images/svg/quotes-blue.svg";
import doubleCircleBig from "../../../images/svg/double-circle-top-right-big.svg";
import doubleCircleSmall from "../../../images/svg/double-circle-top-right-small.svg";
import quotesImg from "../../../images/svg/quotes.svg";
import AppetizeLpStyles from "../../../styles/lp-styles/appetize-lp-styles";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
// const VideoSection = loadable(() =>
//   import("../../../components/VideoSection/VideoSection")
// );
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
// const SuccessStories = loadable(() =>
//   import("../../../components/SuccessStories/SuccessStories")
// );

const whiteGloveImport = "dolce-white-glove.png";

const dolce = () => {
  return (
    <Layout className="labor-management-page">
      <SEO
        title="SpotOn Teamwork | Save time and money managing labor with SpotOn Teamwork"
        description="Increase profits and save time with a two-way integration between your SpotOn point-of-sale system and Dolce’s industry-leading labor management software."
        image={`https://spoton.com/${ogImage}`}
      />
      <HeroLaborManagement sectionData={dolceHero} circleBg={false} />
      <TrustedNumbers numbersArray={dolceTrusted} />
      <LargeFeatures
        sectionData={dolceLargeFeatures}
        style={{ marginTop: "128px" }}
      />

      <BusinessTypes sectionData={dolceBusinessTypesCar} />

      {/* Quote Section */}
      <section className="quotes">
        <img
          className="quotes__quotes-bg"
          src={quotesBackground}
          alt="quotes background"
        />

        <img
          className="quotes__circle-bg-big"
          src={doubleCircleBig}
          alt="circles"
        />
        <img
          className="quotes__circle-bg-small"
          src={doubleCircleSmall}
          alt="circles"
        />

        <img className="quotes__transparent" src={quotesImg} alt="quotes" />
        <div className="quotes__items-wrapper">
          <div
            key={quotes.imageSubtitle}
            className="quotes__item"
            style={{ minHeight: "auto" }}
          >
            <p className="quotes__item--subtext">{quotes.subtext}</p>
            <Image
              className="quotes__item--image"
              imageName={quotes.imageName}
            />
            <div className="quotes__item--title">{quotes.imageTitle}</div>
            <div className="quotes__item--sub-title">
              {quotes.imageSubtitle}
            </div>
          </div>
        </div>
      </section>

      {/* <SuccessStories sectionData={heroSuccess} slides={argantiSlides} /> */}
      {/* <VideoSection sectionData={marketingVideo} /> */}
      <PartnerLogos />
      <ProductLines
        sectionData={dolceSolutionsSection}
        productSlides={dolceSolutionsSlides}
      />
      <TestmonialReviews sectionData={dolceTestimonials} />
      <WhiteGlove
        sectionData={dolceWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={dolceCTA} />
      {/* <PartnerLogos />  */}
      {/* <Faqs sectionData={marketingFaqs} /> */}

      <AppetizeLpStyles />
    </Layout>
  );
};

export default dolce;
